<template>
  <div class="container-fluid main-color">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="page-title">Add OJT Criteria Form</h4>
          <div class="col-2">
            <v-select
              class=""
              name="formType"
              :clearable="false"
              id="formType"
              v-model="selectedFormType"
              :options="formTypes"
              @option:selected="((val) => {
                formData = {};
              })"
            />
          </div>
        </div>
      </div>
    </div>
   
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form v-if="selectedFormType == 'Single'">
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="name">
                    Criteria Name
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <input
                    name="name"
                    id="name"
                    type="text"
                    class="form-control"
                    :class="{
                      'p-invalid': this.v$.formData.name.$error,
                    }"
                    v-model="formData.name"
                  />

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.formData.name.$errors,
                      value: 'Name',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="program">
                    Program Name
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <div class="d-flex">
                    <div class="d-flex align-items-center me-3">
                      <input
                        type="radio"
                        name="choose"
                        :checked="formData.all_check"
                        @click="
                        formData.all_check = 1;
                        formData.level = '';
                        formData.assessment_criteria_id = '';
                        "
                      />
                      <label>All</label>
                    </div>
                    <div class="d-flex align-items-center">
                      <input
                        type="radio"
                        name="choose"
                        :checked="!formData.all_check"
                        @click="
                          formData.all_check = 0;
                          formData.level = '';
                          formData.assessment_criteria_id = ''
                          formData.program_ids = [];
                        "
                      />
                      <label>Select</label>
                    </div>
                  </div>

                  <v-select
                    v-show="!formData.all_check"
                    name="program"
                    @option:selected=" 
                    formData.level = '';
                    formData.assessment_criteria_id = '';
                    "
                    @option:deselected=" 
                    formData.level = '';
                    formData.assessment_criteria_id = '';
                    "
                    id="program"
                    :options="moduleList"
                    label="name"
                    :loading="!moduleList.length"
                    :class="{
                      'p-invalid': this.v$.formData.program_ids.$error,
                    }"
                    v-model="formData.program_ids"
                    :selectable="(data) => selectedProgram(data)"
                    multiple
                  />

                  <v-errors
                    v-show="!formData.all_check"
                    :vuelidateErrors="{
                      errors: this.v$.formData.program_ids.$errors,
                      value: 'Program',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="remark">
                    Level
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <v-select
                    name="level"
                    id="level"
                    :options="getLevel"
                    label="name"
                    :clearable="false"
                    @option:selected=" 
                    formData.assessment_criteria_id = '';
                    "
                    @option:deselected=" 
                    formData.assessment_criteria_id = '';
                    "
                    :class="{
                      'p-invalid': this.v$.formData.level.$error,
                    }"
                    v-model="formData.level"
                    :selectable="
                      (option) => option.name !== formData.level?.name
                    "
                  />

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.formData.level.$errors,
                      value: 'Level',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                    
                    <div class="col-lg-3">
                      <label for="type"> 
                          HRD Type 
                      </label>
                    </div>

                    <div class="col-lg-6">

                          <v-select 
                          :options="hrdTypes"
                          label="name" 
                          class="border-danger"
                          :selectable="(option) => option != formData.hrd_type"
                          v-model="formData.hrd_type"
                          :clearable="false"
                          />
                    </div>

                </div>

              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="type">
                    Assessment Criteria
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <v-select
                    :options="filterAssessmentCriteria"
                    label="name"
                    class="border-danger"
                    :clearable="false"
                    :class="{
                      'p-invalid':
                        this.v$.formData.assessment_criteria_id.$error,
                    }"
                    v-model="formData.assessment_criteria_id"
                    :selectable="
                      (val) => val.id != formData.assessment_criteria_id?.id
                    "
                  />

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.formData.assessment_criteria_id.$errors,
                      value: 'Assessment Criteria',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="remark">
                    Lowest Score
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <input
                    type="number"
                    min="1"
                    max="5"
                    class="form-control"
                    :class="{
                      'p-invalid': this.v$.formData.lowest_score.$error,
                    }"
                    v-model="formData.lowest_score"
                  />

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.formData.lowest_score.$errors,
                      value: 'Percentage',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="remark">
                    Highest Score
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <input
                    type="number"
                    min="1"
                    max="5"
                    class="form-control"
                    :class="{
                      'p-invalid': this.v$.formData.highest_score.$error,
                    }"
                    v-model="formData.highest_score"
                  />

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.formData.highest_score.$errors,
                      value: 'Percentage',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mx-auto">
                  <div class="mt-3 mb-3 ms-3 text-center">
                    <router-link
                      :to="{ name: 'ojt-criteria' }"
                    >
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>

                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="store()"
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>

                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <MultipleCreate v-if="selectedFormType == 'Multiple'" :assessmentCriteria="assessmentCriteria"/>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import { levels } from "../../../const";
import { types } from "../../../const";

import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import validationErrors from "../../../mixins/validationErrors";
import MultipleCreate from "./MultipleCreate.vue";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },

  components: {
    vSelect,
    MultipleCreate
  },

  mixins: [validationErrors],

  data() {
    return {
      hrdTypes: [
      {
        id: 1,
        name: 'Behavior Assessment',
        value: null
      },
      {
        id: 2,
        name: 'Funtional Field Assessment',
        value: 'Functional'
      }, 
      {
        id: 3,
        name: 'Rule & Regulation Assessment',
        value: 'Rule'
      }
    ],
      formData: {
        name: "",
        program_ids: [],
        level: "",
        hrd_type: null,
        assessment_criteria_id: null,
        lowest_score: "",
        highest_score: "",
        type: "OJT",
        all_check: 1,
      },
      moduleList: this.$store.getters["odoo/getAllPrograms"],
      assessmentCriteria: [],
      formTypes: ["Single", "Multiple"],
      selectedFormType: "Single",
      getLevel: levels,
      getType: types,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },

  validations() {
    return {
      formData: {
        name: { required },
        level: { required },
        program_ids: this.formData.all_check ? "" : { required },
        assessment_criteria_id: { required },
        lowest_score: { required },
        highest_score: { required },
      },
    };
  },

  methods: {

    async getAssessmentCriteria() {
      await axios
        .get(`${this.baseUrl}admin/v2/assessment-criteria?type=OJT&&status=1`)
        .then((res) => {
          this.assessmentCriteria = res.data.data;
        });
    },

    async store() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) 
        return;
      
      this.isLoading = true;
      let sendData = {};
      sendData.name = this.formData.name;
      sendData.level = this.formData.level.name;
      sendData.hrd_type = !this.formData.hrd_type.value || this.formData.hrd_type.value  == "Behavior Assessment" ? null : this.formData.hrd_type.value;
      sendData.program_ids = this.formData.all_check
        ? this.moduleList.map((val) => val.id)
        : this.formData.program_ids.map((val) => val.id);
        sendData.assessment_criteria_id = this.formData.assessment_criteria_id.id;
      sendData.type = this.formData.type;
      sendData.lowest_score = this.formData.lowest_score;
      sendData.highest_score = this.formData.highest_score;
      sendData.all_check = this.formData.all_check;
      
      await axios
        .post(`${this.baseUrl}admin/v2/hrd-criteria`, sendData)

        .then(() => {
          this.isLoading = false;
          this.$router.push({ name: "ojt-criteria" });
          this.toast.success("Successfully Created Criteria");
        }).catch(() =>{
          this.isLoading = false;
          this.toast.error("Something with wrong");
        });
      
    },

    selectedProgram(option) {
      return !this.formData.program_ids.length
        ? true
        : this.formData.program_ids.find((p) => p.id == option.id) ===
            undefined;
    },
  },
  watch: {
    "formData.lowest_score"(data) {
      if (!(data > 0 && data <= 5)) {
        this.formData.lowest_score = "";
      }
    },
    "formData.highest_score"(data) {
      if (!(data > 0 && data <= 5)) {
        this.formData.highest_score = "";
      }
    },
  },
  computed : {
      filterAssessmentCriteria() {
        if(this.formData.all_check ) {
            return  this.assessmentCriteria.filter((val) => {
                  return val.all_check && val.level == this.formData.level?.name;
          })}else{
          return this.assessmentCriteria.filter((val)  => {
				if(this.formData.program_ids.length){
					let p_ids = val.programs.map((p) => p.id)
                  let flag = true;
                       Object.values(this.formData.program_ids.map((val)=>val.id)).some((p) => {
                          if(! p_ids.includes(p)){
                            flag = false;
                          }
        
                      })
                    return flag && val.level == this.formData.level?.name;
				}			
                  
          })
        }
    
      },  
  },
  async created() {
    await this.getAssessmentCriteria();
  },
};
</script>
